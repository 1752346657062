<template>
  <div class="footer">
    <h1 class="title">Хочешь работать в Createx? Напиши нам</h1>
    <div class="footer-links">
      <a href="https://t.me/valastn"
        ><img src="../assets/images/footer/Telegram.svg"
      /></a>
      <a href="mailto:hr@createx.by"
        ><img src="../assets/images/footer/Gmail.svg"
      /></a>
      <a href="https://www.linkedin.com/company/createxby"
        ><img src="../assets/images/footer/LinkedIn.svg"
      /></a>
    </div>
    <div class="text">А также можешь следить за нами в соцсетях</div>
    <div class="footer-links">
      <a
        href="https://www.instagram.com/createx.by?igsh=Mnkwb2dtNjU4eXBi"
        class="small"
        ><img src="../assets/images/footer/Instagram.svg"
      /></a>
      <a
        href="https://www.tiktok.com/@team.createx?_t=8rNraKlQwXo&_r=1"
        class="small"
        ><img src="../assets/images/footer/TikTok.svg"
      /></a>
    </div>
    <div class="copyright">© ООО “Крейтекс”, 2024</div>
    <div class="bg-image">
      <img src="../assets/images/footer/footer-background.png" />
    </div>
    <div class="company-text">
      <img src="../assets/images/footer/createx-text.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCopyright",
};
</script>

<style lang="sass">
@import "../styles/variables.sass"

.footer
  padding: 69px 16px 24px
  position: relative
  overflow: hidden
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  .title
    font-size: 32px
    font-weight: 510
    line-height: 41.6px
    text-align: center
    color: #FFFFFF
    margin-bottom: 24px

    @media screen and (min-width: $mobile_medium)
      font-size: 50px
      line-height: 65px

    @media screen and (min-width: $desktop_small)
      font-size: 56px
      line-height: 72.8px
      text-align: left

  .footer-links
    display: flex
    flex-direction: row
    gap: 40px
    margin-bottom: 44px

    a
      width: 80px
      height: 80px
      cursor: url("../assets/cursor.svg"), auto
      border-radius: 40000px
      box-shadow: 0px 0px 1px 0px #5853A240, 1px 2px 2px 0px #5853A236, 2px 4px 3px 0px #5853A221, 3px 7px 3px 0px #5853A20A, 5px 12px 4px 0px #5853A200

      &:hover
        border-radius: 800px
        box-shadow: 0px 0px 1px 0px #5853A240, 1px 2px 2px 0px #5853A236, 2px 4px 3px 0px #5853A221, 3px 7px 3px 0px #5853A20A, 5px 12px 4px 0px #5853A200

      &.small
        width: 70px
        height: 70px

      img
        width: 100%

  .text
    opacity: 60%
    font-size: 24px
    font-weight: 510
    line-height: 31.2px
    text-align: center
    color: #FFFFFF
    margin-bottom: 24px

    @media screen and (min-width: $mobile_medium)
      font-size: 32px
      line-height: 41.6px

    @media screen and (min-width: $desktop_small)
      font-size: 40px
      line-height: 52px

  .copyright
    opacity: 50%
    font-size: 18px
    font-weight: 400
    line-height: 27px
    text-align: center
    color: #FFFFFF
    margin-top: 60px

  .bg-image
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    z-index: -1

    img
      object-fit: cover
      width: 100%
      height: 100%

  .company-text
    width: 100%
    max-width: fit-content
    height: auto
    position: absolute
    bottom: 0
    z-index: -1

    img
      height: fit-content
      width: 100%
</style>
